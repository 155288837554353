import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSocialSharing from "vue-social-sharing";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

createApp(App)
  .use(store)
  .use(router)
  .use(MotionPlugin)
  .use(VueSocialSharing)
  .use(ElementPlus)
  .mount("#app");
