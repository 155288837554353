<template>
  <div v-if="isVisible" class="notification">
    {{ message }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      message: "",
    };
  },
  methods: {
    showNotification(message) {
      this.message = message;
      this.isVisible = true;
      setTimeout(() => {
        this.isVisible = false;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  z-index: 9999;
}
</style>
