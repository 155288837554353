<template>
  <TheHeader v-if="isLoaded && !isAsSystem" />
  <TheAsHeader v-if="isLoaded && useAsHeader" />
  <TheLoader v-if="!isLoaded" />
  <router-view v-if="isLoaded" />
  <TheFooter v-if="isLoaded" />
  <Notification ref="notification" />
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheLoader from "@/components/TheLoader.vue";
import TheAsHeader from "@/components/TheAsHeader.vue";
import Notification from "@/views/As/NotificationView.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
    TheLoader,
    TheAsHeader,
    Notification,
  },
  mounted() {
    window.scrollTo(0, 0);
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = true;
      }
    };
    this.checkRoute();
  },
  data() {
    return {
      isLoaded: false,
      useAsHeader: false,
    };
  },
  watch: {
    $route: "checkRoute",
  },
  methods: {
    checkRoute() {
      const path = this.$route.path;
      this.useAsHeader =
        path.includes("/web-system") ||
        path.includes("/page") ||
        path.includes("/add-page");
    },
    showAlert(message) {
      this.$refs.notification.showNotification(message);
    },
  },
};
</script>

<style lang="scss">
// IMPORT GOOGLE FONTS

// Libre Bodoni
@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&display=swap");
// Quicksand
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
// Montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
// Sofia Sans Condensed
@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:wght@400;500;600;700;800&display=swap");

// IMPORT GLOBAL COLORS
@import "@/assets/sass/_colors.scss";

body {
  margin: 0;
  overflow-x: hidden;
  background: $text-invert-color;
}

// h1,
// p {
//   background: #fff;
// }

#app {
  font-family: "Quicksand", serif;
  color: $text-color;

  width: 100vw;
  height: auto;
  overflow-x: hidden;
}

[v-cloak] {
  display: none;
}

.button {
  transition-duration: 0.1s;
  cursor: pointer;
}

.button:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 0.1em 0.5em #333 !important;
}

.animation-time {
  transition-duration: 0.15s;
}

h1 {
  font-family: "Sofia Sans Condensed", sans-serif;
  font-size: 3.7em;
  width: 90%;
  margin: 2em auto 1em auto;
  margin-top: 3.4em !important;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  h1 {
    margin-top: 3.2em !important;
  }
}
@media screen and (max-width: 900px) {
  h1 {
    font-size: 3em;
    margin: 2em auto 1em auto;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.2em;
    margin-top: 4.2em !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #444444;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #1f554b;
}
::-webkit-scrollbar-track {
  background-color: rgb(200 200 200);
}

::selection {
  color: rgb(31 85 75);
  background: #ffeb3bbd;
}
</style>
